.navlistcontent {
    width: 1000px;
    margin-left: -110px;
    margin-top: 20px;
    position: relative;
}

.navmonth {
    position: relative;
    z-index: 10;
    height: 43px;
    width: 1000px;
}

.navmonth li {
    width: 54px;
    height: 37px;
    font-size: 12px;
    line-height: 37px;
    margin-left: 26px;
    float: left;
    font-family: 'Microsoft YaHei';
    cursor: pointer;
    text-align: center;
}

.preBlueBg {
    color: #fff;

    background: url(//p0.ifengimg.com/fe/news_zairenjian_homepage/images/month_bg_78e31dcc.jpg) no-repeat;
}

.gray {
    color: #d2d2d2;
    cursor: auto;
}

.navmonth li span {
    font-size: 18px;
    width: 20px;
    text-align: center;
    margin-right: 4px;
}

/* 
.navmonth li.onClass {
    background: #009ee0
}

.navmonth li.noneClass {
    background: 0;
    color: #d2d2d2
} */

.navmonthlist {
    width: 1086px;

    margin-top: 10px;
    position: relative;
    left: -43px;
    margin-left: 66px;
}

.navmonthlistUL {
    composes: clearfix from global;
    /* display: flex;
    flex-wrap: wrap; */
}

/* 
.fl {
    float: left;
    margin-right: 64px
} */

.monthlist01 {
    position: relative;
    margin-right: 64px;
    width: 200px;
    float: left;
}

.monthlist01 a {
    display: block;
}
.monthlist01 a:hover {
    text-decoration: none;
}

.imgWrap {
    overflow: hidden;

    width: 200px;
    height: 112px;
    overflow: hidden;
}

.imgWrap img {
    width: 100%;
    height: 100%;
    display: block;
}

.imgWrapFocus {
    /*-webkit-box-shadow:3px 3px 2px #d8d8d8*/
}

.p01 {
    font-size: 12px;
    color: #fff;
    margin-top: 12px;
    position: absolute;
    width: 82px;
    height: 30px;
    left: -8px;
    top: 0;
    background: url(//p0.ifengimg.com/fe/news_zairenjian_homepage/images/num_298fc857.png) no-repeat;
    line-height: 23px;
    text-align: center;
    font-weight: bold;
    font-family: 'Microsft YaHei';
}

.p02 {
    font-size: 14px;
    color: #222222;
    line-height: 36px;
    margin-bottom: 24px;
}

.p03 {
    color: #009ee0;
}
