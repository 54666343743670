.wrap {
    height: 44px;
    border-bottom: 1px solid #dcdcdc;
}

.nav {
    composes: clearfix from global;
    width: 1000px;
    margin: 0 auto;

    position: relative;

    & img {
        width: 161px;
        height: 27px;
        padding-top: 11px;
        float: left;
    }
}

.list {
    float: right;

    & li {
        color: #999;
        line-height: 44px;
        float: left;
        margin-left: 10px;
    }

    & a {
        color: #999 !important;
    }
}
