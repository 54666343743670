.nav {
    width: 100%;
    position: relative;
}

.navlist {
    position: relative;

    width: 828px;
    margin: 0 auto;
}

.navlistbg {
    position: relative;
    width: 660px;
    margin: 0;
    overflow: hidden;
}

.navlistyear {
    display: inline-block;
    height: 57px;
    width: 110px;
    cursor: pointer;
    *display: inline;
    *zoom: 1;
    position: relative;
    line-height: 44px;
    padding-top: 16px;
}

.sliderWrap {
}

.navlistyear span {
    font-size: 18px;
    color: #555;
    font-weight: bold;
    display: block;
    text-align: center;
    font-family: 'Microsoft YaHei';
}

.navlistyear span:hover {
    color: rgb(0, 160, 231);
}

.img {
    display: inline-block;
    background: url(//p0.ifengimg.com/fe/news_zairenjian_homepage/images/img1_4ece5846.jpg) no-repeat;
    width: 7px;
    height: 18px;
    overflow: hidden;
    position: absolute;
    left: 50%;
    margin-left: -3.5px;
    top: 0;
}

.img2 {
    composes: img;
    background: url(//p0.ifengimg.com/fe/news_zairenjian_homepage/images/img2_c2b95d2c.jpg) no-repeat;
}

.buttonPrev,
.buttonNext {
    position: absolute;
    top: 25px;
    width: 20px;
    height: 20px;
    z-index: 1000;
    cursor: pointer;
}

.buttonPrev {
    background: url(./images/left_arrow.png) no-repeat;
    right: 90px;
    left: auto;
    z-index: 1000;
}

.buttonNext {
    background: url(./images/right_arrow.png) no-repeat;
    right: 60px;
    left: auto;
    z-index: 1000;
}

.buttonNext.buttonDisabled {
    opacity: 1;
    background: url(./images/right_arrow_hide.png) no-repeat;
    cursor: auto;
    pointer-events: none;
}

.buttonPrev.buttonDisabled {
    opacity: 1;
    background: url(./images/left_arrow_hide.png) no-repeat;
    cursor: auto;
    pointer-events: none;
}

.hr_month {
    width: 1000px;
    height: 1px;
    background-color: #eee;
    margin: 0 auto;
    margin-left: -86px;
    margin-bottom: 20px;
}
